<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--toman transaction manual-->
            <b-modal
                    ref="toman-modal"
                    title="تایید واریزی"
                    ok-title="ثبت پرداخت"
                    cancel-title="لغو"
                    v-model="tomanModal"
                    @ok.prevent="$S2N(label.amount) ? doRequest(label,true) : ''"
            >
                <b-overlay :show="loading"
                           rounded="sm">
                    <b-form label="">
                        <p>
                            بعد از بررسی شناسه تراکنش زیر جهت تایید مقدار واریزی را در کادر مربوطه وارد نمایید.
                        </p>
                        <p>
                            {{label.hashCode}}
                        </p>
                        <b-form-group class="mt-1" label="مقدار واریزی">
                            <b-form-input v-model="label.amount" placeholder="مقدار پرداختی"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <div class="m-1 d-flex justify-content-between"
                 dir="rtl"
            >
                <!--<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
                    &lt;!&ndash;                    <feather-icon icon="FilterIcon"/>&ndash;&gt;
                    <img width="15" src="../../assets/images/excel.png" alt="" class="mr-50"
                         style="filter: invert(100%)"/>
                    خروجی اکسل درخواست های تومانی
                </b-button>-->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >
                    <template #cell(hashCode)="data">
                        <b-link :href="data.item.link || '#'" target="_blank">
                            {{data.item.hashCode}}
                        </b-link>
                    </template>

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{$G2J(data.item.createdAtDateTime)}}
                        </span>
                    </template>

                    <template #cell(relatedCoin)="data">
                        <b-avatar
                                size="25"
                                class="mr-50"
                                :src="`/coins/${data.item.relatedCoin}.png`"
                                variant="light-info"
                        />
                        {{$coins[data.item.relatedCoin].persianName}}
                    </template>

                    <template #cell(action)="{item}">
                        <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
                            <b-dropdown
                                    id="dropdown-offset"
                                    variant="link"
                                    no-caret
                                    offset="80px"
                                    :right="true"
                            >
                                <template #button-content>
                                    <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="align-middle text-body"
                                    />
                                </template>
                                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                                 @click="checkTransaction(item)">
                                    <feather-icon class="text-warning" icon="RotateCcwIcon"/>
                                    <span class="text-warning align-middle ml-50">بررسی اتوماتیک</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="beforeRequest(item)">
                                    <feather-icon class="text-success" icon="CheckSquareIcon"/>
                                    <span class="text-success align-middle ml-50">تایید اجباری</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                                 @click="doRequest(item)">
                                    <feather-icon class="text-danger" icon="XCircleIcon"/>
                                    <span class="text-danger align-middle ml-50">رد اجباری</span>
                                </b-dropdown-item>

                                <!-- <b-dropdown-item>-->
                                <!--    <feather-icon icon="FileTextIcon"/>-->
                                <!--    <span class="align-middle ml-50">جزییات سفارش</span>-->
                                <!-- </b-dropdown-item>-->
                            </b-dropdown>
                        </template>
                        <not-allowed v-else/>
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>

                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BAvatar,
        BButton,
        BCard,
        BDropdown,
        BDropdownItem,
        BForm,
        BFormGroup,
        BFormInput,
        BLink,
        BOverlay,
        BPagination,
        BTable,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    import NotAllowed from "@/layouts/components/NotAllowed";
    import {coinFilterArray} from "@/libs/globalConstants";

    export default {
        name: 'Withdraw',
        components: {
            NotAllowed,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            BDropdown,
            BDropdownItem,
            BLink,
            // BBadge,
            BAvatar,
            vSelect,
            BForm,
            BFormInput,
            BFormGroup,
            // BInputGroup,
            // BInputGroupAppend,
            BOverlay,
            // BListGroup,
            // BListGroupItem,

        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 20,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            label: {
                amount:''
            },
            manualDefray: {
                txid : '',
                amount : '',
            },
            transactionHash: '',
            tomanModal: false,
            vandarModal: false,
            cryptoModal: false,
            isActive: false,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            passwordType: 'password',
            vandar: {
                mobile: '',
                password: ''
            },
            columns: [
                {
                    label: 'نام کاربر',
                    key: 'customer',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'واحد واریزی',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'select',
                    selectOptions: coinFilterArray
                },
                {
                    label: 'شناسه تراکنش',
                    key: 'hashCode',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'تاریخ درخواست',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'عملیات',
                    key: 'action'
                },
            ],
            walletName: '',
            walletUnit: '',
            request: '',
            selectedWallet: '',
            walletsList: '',
            password: '',
            loading: false,
            wallets: [
                {name: '', totalBalance: 0},
            ],
        }),
        computed: {
            statusVariant() {
                const a = {
                    inactive: 'danger',
                    active: 'success',
                }
                return e => a[e];
            },
            statusLabel() {
                const a = {
                    inactive: 'لغو شده',
                    active: 'انجام شده',
                }
                return e => a[e];
            },
        },
        methods: {
            beforeRequest(e){
                this.label = e
                this.tomanModal = true
            },
            async checkTransaction(e) {
                if (!this.loading) {
                    this.loading = true
                    const res = await this.$axios.post('/wallets/check-deposit/' + e.id)
                    this.loading = false
                    let text
                    if(res?.data?.baseDTO?.amount){
                        text =  `مقدار واریزی ${res?.data?.amount} ${this.$coins[e.relatedCoin].persianName} شاسایی و ثبت شد.`
                    }else{
                        text = 'مقدار واریزی شناسایی نشد.'
                    }
                    this.$error('', text, res?.data?.baseDTO?.transactionIsDone ? 'success' : 'info','تایید')
                    this.getData(this.currentPage, this.perPage)
                }
            },

            doRequest(e, bool) {
                const a = e
                const customer = '\n' + 'کاربر : ' + a.customer
                const amount = e.amount ? '\n' + ' مقدار : ' + this.$toLocal(a.amount) + ' ' + this.$coins[a.relatedCoin].persianName : ''
                const txid = '\n' + ' شناسه تراکنش : ' + a.hashCode
                this.$swal({
                    icon: bool ? 'info' : 'error',
                    title: `آیا از ${bool ? 'تایید' : 'رد'} درخواست کاربر مطمئن هستید؟`,
                    confirmButtonText: 'بررسی کرده و مطمئن هستم',
                    showCancelButton: true,
                    cancelButtonText: 'خیر',
                    text: "مشخصات درخواست :" + customer + amount + txid,
                    customClass: {
                        confirmButton: 'btn' + (bool ? ' btn-success' : ' btn-danger'),
                        cancelButton: 'btn btn-primary ml-1',
                    },
                }).then(async (result) => {
                    if (bool && result.value) {
                        this.state.loading = true
                        const address = '/wallets/accept-deposit'
                        await this.$axios.post(address,this.label)

                        this.$error('درخواست واریز ثبت شد.','','success','تایید')

                        await this.getData(this.currentPage, this.perPage)

                        this.tomanModal =false

                    }else if(!bool && result.value){
                        this.state.loading = true
                        const address = '/wallets/cancel-deposit/' + this.label?.id
                        await this.$axios.post(address)

                        this.$error('درخواست واریز رد شد.','','info','تایید')

                        await this.getData(this.currentPage, this.perPage)
                    }
                })
            },

            sort(e) {
                console.log(e)

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })

                this.getData(1, this.perPage)

            },

            async getData(page = 1, perPage = this.perPage) {
                this.state.loading = true

                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                // const res = await this.$axios('/wallets/deposit-requests?withdrawalRequestStatus=RAISED_BY_USER', {params: queryParams})
                const res = await this.$axios('/wallets/deposit?depositRequestStatus=PENDING', {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        }
    }
</script>
<style lang="scss">
    .vs__selected {
        width: calc(100% - 20px);
    }

    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
